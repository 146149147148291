<template>
	<v-card flat outlined>
		<v-card-title>Select an invoice address</v-card-title>
		<v-card-text
			><v-data-table
				v-model="selectedItem"
				:headers="tableHeaders"
				:items="invoiceAddresses"
				item-key="addressId"
				dense
				disable-sort
				hide-default-footer
				single-select
				show-select
				v-on:item-selected="onAddressSelected"
			/>
		</v-card-text>
	</v-card>
</template>

<script>
	import { mapGetters } from "vuex";
	export default {
		name: "OrderInvoiceForm",
		computed: {
			...mapGetters({
				cart: "shoppingCart/cart",
				invoiceAddresses: "account/invoiceAddresses",
			}),
		},
		data() {
			return {
				selectedItem: [],
				tableHeaders: [
					{ text: "addressType", value: "addressType" },
					{ text: "attentionTo", value: "attentionTo" },
					{
						text: "streetNameAndNumber",
						value: "streetNameAndNumber",
					},
					{ text: "zipCode", value: "zipCode" },
					{ text: "city", value: "city" },
					{ text: "countryCode", value: "countryCode" },
					{ text: "addressId", value: "addressId" },
				],
			};
		},
		methods: {
			onAddressSelected(pSelected) {
				var x = null;
				if (pSelected.value === true) {
					x = pSelected.item.addressId;
				}

				this.$store.dispatch("shoppingCart/setInvoiceAddressId", x);
			},
		},
	};
</script>

